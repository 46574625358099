@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';
/* You can add glorbal styles to this file, and also import other style files */

@import "~ngx-smart-modal/ngx-smart-modal";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Oswald', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
}

body p {
  font-family: 'Oswald', sans-serif;
  margin: 0px;
}


input {
  font-family: 'Oswald', sans-serif;
}

mat-sidenav-container {
  height: 100%;
}

.mat-drawer.mat-drawer-side {
  z-index: 1;
}

button.btn {
  background-color: #ED1D24;
  border: none;
  padding: 12px 16px;
  border-radius: 4px;
  font-size: 0.875em;
  color: #FFFFFF;
  font-family: 'Oswald', sans-serif;
  font-weight: 600;
  line-height: 16.8px;
  text-transform: uppercase;
  transition: all 0.2s ease-in;
}

button.btn:hover {
  background-color: #c22429;
}

.data-row:hover {
  background-color: #FFFBF7;
  cursor: pointer;
}

.mat-form-field-wrapper {
  padding-bottom: 16px;
}

.information-holder {
  padding-top: 24px;
}

.row-label {
  font-size: 1em;
  color: #858A8C;
  text-transform: uppercase;
}

.row-data {
  font-size: 1em;
  font-weight: bold;
  color: #0A233F
}


.date-container {
  width: max-content;
  background-color: #0A233F;
  font-size: 1.1em;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  margin-top: 10px;
  box-shadow: 0px 4px 16px rgba(36, 38, 38, 0.12);
  font-weight: 400;
  text-transform: uppercase;
}

.documents a {
  font-size: 1.2em;
  text-decoration: underline;
  cursor: pointer;
}

.documents .document-link {
  padding-top: 24px;
}

.documents .document-link img {
  padding-right: 8px;
}

.centered-content {
  display: flex;
  align-content: center;
  align-items: center;
}
